import React from "react";
import Logo from "../../assets/images/updatedLogo.png";
import Watermark from "../../assets/images/watermark.svg";
import Linkedin from "../../assets/images/linkedin.png";

import "./footer.css";
import { useLocation, useNavigate } from "react-router-dom";

const Footer = (props) => {
  const navigate = useNavigate();

  const handleClick=()=>{

      navigate("/");
    
  }
  const location = useLocation();

  const routes = [
    '/',
    '/careers',
    '/contact-us','/products','/industry-solutions','/blog','/about-us','/product-detail','/products-listing','/privacy-policy','/industry-solution-details'
  
  ];
  const currentPath = location.pathname;
  const isKnownRoute = routes.includes(currentPath);
  console.log(isKnownRoute,"is known route")
  return (
    isKnownRoute ?
    <div className="footer-container">
      <footer className="footer">
        <div className="footer-content">
          <div className="footer-content-column logo-column">
            <div className="footer-logo">
              <a className="footer-logo-link" href="/">
                <img src={Logo} alt="image160I2026"  />
              </a>
              <div className="footer-description">
                Efficient, Together
              </div>
            </div>
            
            <div className="footer-heading follow-us-container mobileHide">
              <span className="follow-text">Follow Us On :</span>
              <span>
                <a
                  href="https://www.linkedin.com/company/uni-klinger/"
                  target="_blank" rel="noopener noreferrer"
                >
                  {" "}
                  <img src={Linkedin} alt="LinkedIn" />{" "}
                </a>
              </span>
            </div>
          </div>
          <div className="footer-content-column watermark-column .d-none .d-sm-block" style={{position:'relative'}}>
            {/* <img src={Watermark} alt="watermark" className="watermarkLogo" /> */}
          </div>
          <div className="footer-content-column  links-column">
            <div className="footer-links">
              {/* <span className="footer-text04">
                <a className="footer-logo-link" href="#">
                  About Us
                </a>
              </span> */}
              <span className="footer-text04">
                <a className="footer-logo-link" href="/products">
                  Products
                </a>
              </span>
              <span className="footer-text04">
                <a className="footer-logo-link" href="/industry-solutions">
                  Solutions
                </a>
              </span>
              <span className="footer-text04">
                <a className="footer-logo-link" href="/careers">
                  Careers
                </a>
              </span>
            </div>
            <div className="footer-links">
              <span className="footer-text04">
                <a className="footer-logo-link" href="/contact-us">
                  Contact Us
                </a>
              </span>
              <span className="footer-text04">
                <a className="footer-logo-link" href="/privacy-policy">
                  Privacy Policy
                </a>
              </span>
              <span className="footer-text04">
                <button className="footer-logo-link" onClick={handleClick} role="link" rel="noopener noreferrer">
                  Terms & Conditions
                </button>
              </span>
            </div>
          </div>
          <div className="footer-heading follow-us-container desktopHide">
            <span className="follow-text">Follow Us On :</span>
            <span>
              <a
                href="https://www.linkedin.com/company/uni-klinger/"
                target="_blank" rel="noopener noreferrer"
              >
                {" "}
                <img src={Linkedin} alt="LinkedIn" />{" "}
              </a>
            </span>
          </div>
        </div>
        <div className="footer-copyright">
          <div className="footer-copyright-wrapper">
            <p className="footer-copyright-text">
              <button
                className="footer-heading"
                style={{ fontSize: 16 }}
             onClick={handleClick}
               rel="noopener noreferrer"
              >
                ©2024 UniKlinger. All rights reserved
              </button>
            </p>
          </div>
        </div>
      </footer>
    </div>
    :null
  );
};

export default Footer;
