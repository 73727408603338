import React, { useEffect,useState } from "react";
import Banner from "../assets/images/industry-solution-details-banner.png";
import MobileBanner from "../assets/images/industry-solution-details-mobile-banner.png";
import PharmaSolution from "../assets/images/pharma-solutions.png";
import ButtonArrow from "../assets/images/button-arrow.svg";
import VideoSection from "../assets/images/video-section.png";
import USP1 from "../assets/images/usp-1.png";
import USP2 from "../assets/images/usp-2.png";
import USP3 from "../assets/images/usp-3.png";
import CaseStudies from "./CaseStudies";
import { Helmet } from "react-helmet-async";

const IndustrySolutionDetails = () => {
  const [screenWidth, setScreenWidth] = useState(0);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    // Create a new ResizeObserver instance
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        setScreenWidth(entry.contentRect.width);
      }
    });

    // Start observing the selected element
    resizeObserver.observe(document.body);
  }, [screenWidth]);

  return (
    <>
    <Helmet>
      <title>Industry Solutions Details</title>
      <link rel="canonical" href="https://uniklinger.com" />

    </Helmet>
    <div className="fluid-container">
      <div>
        <img
          src={screenWidth > 820 ? Banner : MobileBanner}
          alt="banner"
          className="banner-image"
          style={{ paddingTop: '120px' }}
          itemScope
          itemType="https://schema.org/ImageObject"
          itemProp="image"
        />
      </div>
      <div className="pt-50 flex details-container">
        <div className="details-image-container w-3/6">
          <img
            src={PharmaSolution}
            alt="pharma-solution"
            className="w-full"
            itemScope
            itemType="https://schema.org/ImageObject"
            itemProp="image"
          />
        </div>
        <div
          className="details-content-container w-2/5 m-[auto]"
          itemScope
          itemType="https://schema.org/Article"
        >
          <div className="details-title pb-20" itemProp="headline">
            Pharma Solution Innovators
          </div>
          <div className="details-description" itemProp="articleBody">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
            minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
            <br />
            <br />
            Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
            dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
            proident
          </div>
          <div className="pt-20">
            <button className="button-class flex" itemProp="potentialAction" itemScope itemType="https://schema.org/Action">
              <span className="pr-10" itemProp="name">Enquire Now</span>
              <span>
                <img src={ButtonArrow} alt="arrow" className="mt-8" />
              </span>
            </button>
          </div>
        </div>
      </div>
      <div className="pt-50">
        <img
          src={VideoSection}
          alt="video-section"
          className="w-full"
          itemScope
          itemType="https://schema.org/VideoObject"
          itemProp="video"
        />
      </div>
      <div className="pt-50">
        <div className="usp-title flex">
          <span className="font-semibold pr-10">Our USP’s In</span>
          <span itemProp="about">Pharma Industry</span>
        </div>
        <div
          className="grid grid-cols-3 gap-[20px] pt-30 mobile-usps"
          itemScope
          itemType="https://schema.org/ItemList"
          itemProp="mainEntity"
        >
          <div className="p-6 border-primary" itemScope itemType="https://schema.org/Thing">
            <div>
              <img
                src={USP1}
                alt="USP-1"
                className="w-full"
                itemProp="image"
              />
            </div>
            <div className="background-secondary p-20">
              <div className="usp-card-title" itemProp="name">Lorem ipsum dolor</div>
              <div className="usp-card-subtitle pt-10" itemProp="description">
                Lorem ipsum dolor sit amet, consectetur sed doadipiscing elit, sed
                do eiusmod tempo
              </div>
            </div>
          </div>
          <div className="p-6 border-primary" itemScope itemType="https://schema.org/Thing">
            <div>
              <img
                src={USP2}
                alt="USP-2"
                className="w-full"
                itemProp="image"
              />
            </div>
            <div className="background-secondary p-20">
              <div className="usp-card-title" itemProp="name">Lorem ipsum dolor</div>
              <div className="usp-card-subtitle pt-10" itemProp="description">
                Lorem ipsum dolor sit amet, consectetur sed doadipiscing elit, sed
                do eiusmod tempo
              </div>
            </div>
          </div>
          <div className="p-6 border-primary" itemScope itemType="https://schema.org/Thing">
            <div>
              <img
                src={USP3}
                alt="USP-3"
                className="w-full"
                itemProp="image"
              />
            </div>
            <div className="background-secondary p-20">
              <div className="usp-card-title" itemProp="name">Lorem ipsum dolor</div>
              <div className="usp-card-subtitle pt-10" itemProp="description">
                Lorem ipsum dolor sit amet, consectetur sed doadipiscing elit, sed
                do eiusmod tempo
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-50">
        <div className="usp-title flex">
          <span className="font-semibold pr-10">Case</span>
          <span itemProp="about">Study</span>
        </div>
        <CaseStudies />
      </div>
    </div>
  </>
  
  
  
  );
};

export default IndustrySolutionDetails;
