import React, { useEffect, useState } from "react";
import FancyCarousel from "react-fancy-circular-carousel";
import IndustrySec from "./IndustrySec";
import OilRefineries from "../assets/images/oil-refineries.svg";
import ChemicalPetrochemical from "../assets/images/chemical-petrochemical.svg";
import Pharmaceutical from "../assets/images/pharmaceuticals.svg";
import FoodBaverage from "../assets/images/food-beverage.svg";
import Paper from "../assets/images/paper.svg";
import Textiles from "../assets/images/textiles.svg";
import TyreManufacturing from "../assets/images/tyre-manufacturing.svg";

const SolutionsCarousel = () => {
  const images = [
    OilRefineries,
    ChemicalPetrochemical,
    Pharmaceutical,
    FoodBaverage,
    Paper,
    Textiles,
    TyreManufacturing,
  ];
  const info = [
    <IndustrySec industry="oil-refinery" />,
    <IndustrySec industry="chemical-petrochemical" />,
    <IndustrySec industry="pharmaceutical" />,
    <IndustrySec industry="food-beverages" />,
    <IndustrySec industry="paper" />,
    <IndustrySec industry="textiles" />,
    <IndustrySec industry="tyre-manufacturing" />,
  ];
  const [focusElement, setFocusElement] = useState(0);
  const [zoomLevel, setZoomLevel] = useState(1);

  useEffect(() => {
    let buttons = document.querySelectorAll(
      ".fancy-carousel-navigation-button"
    );
    buttons.forEach((button, index) => {
      if (index === 1) {
        button.innerHTML = `<i class="fas fa-chevron-down"></i>`;
      }
      if (index === 0) {
        button.innerHTML = `<i class="fas fa-chevron-down"></i>`;
      }
    });
    let carouselElements = document.querySelectorAll(".fancy-carousel-element");
    carouselElements.forEach((element, index) => {
      if (index === focusElement) {
        element.children[0].style.filter = "none";
      } else {
        element.children[0].style.filter = "grayscale(100%)";
      }
      //   if(index === focusElement){
      //     element.style.width='120px';
      //     element.style.height='120px';
      //   }
      //    if(index === focusElement -1){
      //     element.style.width='80px';
      //     element.style.height='80px';
      //   }
      //    if(index === focusElement + 1){
      //     element.style.width='80px';
      //     element.style.height='80px';
      //   }
      //   if(index === focusElement -2){
      //     element.style.width='40px';
      //     element.style.height='40px';
      //   }
      //    if(index === focusElement + 1){
      //     element.style.width='40px';
      //     element.style.height='40px';
      //   }
    });
  }, [focusElement]);
  const [screenWidth, setScreenWidth] = useState(0);
  useEffect(() => {
    // Create a new ResizeObserver instance
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        setScreenWidth(entry.contentRect.width);
      }
    });

    // Start observing the selected element
    resizeObserver.observe(document.body);
  }, [screenWidth]);
  useEffect(() => {
    // Detect screen width and zoom level
    const updateScreenInfo = () => {
      setScreenWidth(window.innerWidth);
      setZoomLevel(window.outerWidth / window.innerWidth);
    };

    // Initial detection
    updateScreenInfo();

    // Add resize listener
    window.addEventListener("resize", updateScreenInfo);

    return () => {
      window.removeEventListener("resize", updateScreenInfo);
    };
  }, []);
  return (
    <div
      style={{
        display: screenWidth < 1300 && zoomLevel >= 0.9 ? "none" : "block",
        position: "relative",
        height: zoomLevel <= 0.9 ? "1000px":"700px",
      }}
      className="md:mt-[100px] "
    >
      <div
        className="carousel custom-rotating-carousel"
        style={{ transform: "rotate(-90deg)" }}
        itemScope
        itemType="https://schema.org/ImageGallery"
      >
        <FancyCarousel
         images={images}
          carouselRadius={zoomLevel < 0.9 ? 150 : 200} // Adjust carousel size based on zoom level
          peripheralImageRadius={zoomLevel < 0.9 ? 50 : 60}
          centralImageRadius={zoomLevel < 0.5 ? 80 : 0}
          setFocusElement={setFocusElement}
          focusElementStyling={{}}
          borderWidth={0}
          borderHexColor={"808080"}
        />
         <div className="info-box-wrapper" itemScope itemType="https://schema.org/ItemList">
        <div className="info-container">
          <p itemProp="description"> {info[focusElement]} </p>
        </div>
      </div>
      </div>
    </div>
  );
};

export default SolutionsCarousel;
