import React, { useState, useEffect } from "react";
import ProductsBannerListing from "../assets/images/productListingBanner.png";
import SampleListing from "../assets/images/sampleListing.png";

import ButtonArrow from "../assets/images/button-arrow.svg";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Helmet } from "react-helmet-async";

const ProductsListing = () => {
  const [toggle, setToggle] = useState(false);
  const [partnerTab, setPartnerTab] = useState("company");
  const [locationTab, setLocationTab] = useState("manufacturing");
  const products = [
    { title: "Compressed Non Asbestos Joining Sheet", imgSrc: SampleListing },
    { title: "Product 2", imgSrc: SampleListing },
    { title: "Product 3", imgSrc: SampleListing },
    { title: "Product 4", imgSrc: SampleListing },
    { title: "Product 4", imgSrc: SampleListing },

    { title: "Product 4", imgSrc: SampleListing },

    { title: "Product 4", imgSrc: SampleListing },
  ];
  useEffect(() => {
    const toggles = document.getElementsByClassName("toggle");
    const contentDiv = document.getElementsByClassName("content");
    const icons = document.getElementsByClassName("icon");

    for (let i = 0; i < toggles.length; i++) {
      toggles[i].addEventListener("click", () => {
        if (
          parseInt(contentDiv[i].style.height) !== contentDiv[i].scrollHeight
        ) {
          contentDiv[i].style.height = contentDiv[i].scrollHeight + "px";
          toggles[i].style.color = "var(--primary-color)";
          icons[i].classList.remove("fa-plus");
          icons[i].classList.add("fa-minus");
        } else {
          contentDiv[i].style.height = "0px";
          toggles[i].style.color = "#111130";
          icons[i].classList.remove("fa-minus");
          icons[i].classList.add("fa-plus");
        }

        for (let j = 0; j < contentDiv.length; j++) {
          if (j !== i) {
            contentDiv[j].style.height = 0;
            toggles[j].style.color = "#111130";
            icons[j].classList.remove("fa-minus");
            icons[j].classList.add("fa-plus");
          }
        }
      });
    } // Call the function once when the component mounts
  }, [toggle]);

  const handlePartner = (tab) => {
    console.log(tab);
    setPartnerTab(tab);
  };
  return (
    <>
      <Helmet>
        <title>Products Listing</title>
        <meta
          itemProp="description"
          content="Uni Klinger's product range excels in simplicity, durability, reliability, and environmental consciousness."
        />
      </Helmet>
      <div className="fluid-container">
        <div
          className="banner-class relative text-center"
          itemScope
          itemType="https://schema.org/Product"
        >
          <img
            src={ProductsBannerListing}
            alt="Safety Valves"
            className="banner-image"
            description="Safety Valves"
            itemProp="image"
          />
          <div
            className="absolute product-banner-title text-white top-96 left-96"
            itemProp="name"
          >
            Safety Valves
          </div>
          <div
            className="font-16 text-white absolute top-[300px] text-left left-96"
            itemProp="description"
          >
            Uni Klinger's product range excels in simplicity, durability,
            reliability,
            <br /> and environmental consciousness.
          </div>
        </div>
        <div className="product-cards-container pt-50">
          <div class="container">
            <div class="row pt-50">
              <div class="col-md-12">
                {/* <!-- Nav tabs --> */}
                <div class="card flex-align-center flex-col ">
                  <ul
                    class="nav nav-tabs w-full flex-align-center space-around w-[95%]"
                    role="tablist"
                  >
                    <li
                      role="presentation"
                      class="active w-25"
                      onClick={() => handlePartner("company")}
                    >
                      <a
                        href="#home"
                        aria-controls="home"
                        role="tab"
                        data-toggle="tab"
                        className="poppins-regular"
                        title="Fluid Sealing Division"
                      >
                        Fluid Sealing Division
                      </a>
                    </li>
                    <li
                      role="presentation"
                      className="w-25"
                      onClick={() => handlePartner("csr")}
                    >
                      <a
                        href="#profile"
                        aria-controls="profile"
                        role="tab"
                        data-toggle="tab"
                        className="poppins-regular"
                        title="Fluid Control Division"
                      >
                        Fluid Control Division
                      </a>
                    </li>
                    <li
                      role="presentation"
                      className="w-25"
                      onClick={() => handlePartner("services")}
                    >
                      <a
                        href="#messages"
                        aria-controls="messages"
                        role="tab"
                        data-toggle="tab"
                        className="poppins-regular"
                        title="Safety Valve & Control Valve Division"
                      >
                        Safety Valve & Control Valve Division
                      </a>
                    </li>
                  </ul>

                  {/* <!-- Tab panes --> */}

                  <div class="grid grid-cols-4 gap-4">
                    <div
                      class="mt-[45px]"
                      style={{
                        background: "rgb(231, 236, 244)",
                        height: "fit-content",
                        padding: 10,
                        /* border: 3px solid white; */
                        textAlign: "center",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Accordion
                        sx={{
                          background: "rgba(231, 236, 244, 1)",
                          fontFamily: "Poppins",
                        }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                          sx={{ fontWeight: 500 }}
                        >
                          Fluid Control Division{" "}
                        </AccordionSummary>
                        <AccordionDetails>
                          <ul>
                            <li className="list-style">Joining Sheets</li>
                            <li className="list-style">Graphite Sheets (3)</li>

                            <li className="list-style">Expanded PTFE Sheet</li>

                            <li className="list-style">Ready Cut Gaskets</li>

                            <li className="list-style">
                              Metallic / Semi-Metallic Gaskets (4)
                            </li>
                            <li className="list-style">Gland Packing</li>
                          </ul>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion
                        sx={{
                          background: "rgba(231, 236, 244, 1)",
                          fontFamily: "Poppins",
                        }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2-content"
                          id="panel2-header"
                          sx={{ fontWeight: 500 }}
                        >
                          Fluid Sealing Division
                        </AccordionSummary>
                        <AccordionDetails>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Suspendisse malesuada lacus ex, sit amet blandit
                          leo lobortis eget.
                        </AccordionDetails>
                      </Accordion>
                      <Accordion
                        sx={{
                          background: "rgba(231, 236, 244, 1)",
                          fontFamily: "Poppins",
                        }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel3-content"
                          id="panel3-header"
                          sx={{ fontWeight: 500 }}
                        >
                          Safety Valve & Control Valve Division
                        </AccordionSummary>
                        <AccordionDetails>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Suspendisse malesuada lacus ex, sit amet blandit
                          leo lobortis eget.
                        </AccordionDetails>
                      </Accordion>
                    </div>
                    <div class="tab-content poppins-regular ml-10 col-span-3 ">
                      <div
                        role="tabpanel"
                        class="tab-pane active grid grid-cols-3 "
                        id="home"
                        className={`tab-pane ${
                          partnerTab === "company" ? "active" : ""
                        }`}
                      >
                        {/* <img src={AboutImg3} alt="card-image" style={{ height: 600, width: 360 }} className="banner-image p-1 h-full" /> */}

                        {products.map((product, index) => (
                          <div
                            className="product-listing-card flex flex-col"
                            itemProp="offers"
                            itemScope
                            itemType="https://schema.org/Offer"
                          >
                            <img
                              src={SampleListing}
                              alt="Compressed Non Asbestos Joining Sheet"
                              description="Compressed Non Asbestos Joining Shee"
                              className="h-2/3 w-full"
                              itemProp="image"
                            />
                            <div
                              className="product-listing-card-title w-full text-left ml-5 mb-5 "
                              itemProp="name"
                            >
                              Compressed Non Asbestos Joining Sheet{" "}
                            </div>
                            <div className="hover-buttons ">
                              <button className="view-more   d-flex items-center">
                                <span className="text-[12px] text-black text-center">
                                  View More
                                </span>
                              </button>

                              <button className="enquire text-center d-flex items-center">
                                <span className=" text-[12px] mr-1">
                                  Enquire Now
                                </span>
                                <span>
                                  <img
                                    src={ButtonArrow}
                                    alt="arrow"
                                    width={10}
                                    height={10}
                                    className="mt-0"
                                    description="arrow"
                                  />
                                </span>
                              </button>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div
                        role="tabpanel"
                        class="tab-pane"
                        id="profile"
                        style={
                          partnerTab === "csr"
                            ? { display: "flex", flexDirection: "row" }
                            : { display: "none" }
                        }
                      >
                        {" "}
                        {/* <img src={AboutImg3} alt="card-image" style={{ height: 600, width: 360 }} className="banner-image p-1 h-full" /> */}
                        <div className="col-md-8 p-30 mt-10" style={{}}>
                          <div className="font-46">
                            <span className="color-blue">
                              Engineer. Innovate.
                            </span>
                            <br />
                            <span className="color-red">
                              Deliver. Secure. Repeat
                            </span>
                          </div>
                          <div className="font-16-regular ">
                            Uniklinger embodies a philosophy of constant
                            progress and innovation encapsulated in the phrase
                            "Engineer. Innovate. Deliver. Secure." This
                            commitment starts with their team of skilled
                            engineers who design and develop top-notch fluid
                            control and sealing solutions.
                            <br />
                            <br />
                            Uniklinger doesn't just stop there, though. They
                            prioritize continuous improvement and stay at the
                            forefront of the industry by actively seeking new
                            and advanced solutions. Delivering on their promises
                            is equally important.
                          </div>
                        </div>
                      </div>
                      <div
                        role="tabpanel"
                        class="tab-pane"
                        id="messages"
                        style={
                          partnerTab === "services"
                            ? {
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }
                            : { display: "none" }
                        }
                      >
                        <div className="big-heading">
                          Piston Valves & Bellow Sealed Valves
                        </div>
                        <div className="w-full flex flex-row justify-between">
                          <div
                            className="row ml-0 mr-0 mt-10 resources-card"
                            style={{
                              height: 300,
                              width: "32%",
                              backgroundColor: "#F4D7DF",
                            }}
                            itemScope
                            itemType="https://schema.org/Product"
                          >
                            <div className="relative ">
                              {/* <img style={{ height: '30%', width: '30%', float: 'right', marginBottom: 10 }} src={Piston} /> */}
                              <div className="p-10 pt-[100px]">
                                <div className="heading " itemProp="name">
                                  Piston Valves
                                </div>
                                <div className=" small-regular w-full font-14 pt-5" itemProp="description">
                                  Explore the engineering marvels behind Uni
                                  Klinger's Piston Valves, designed for precise
                                  fluid regulation in various industrial
                                  applications. Learn about the innovative
                                  features that ensure reliability, durability,
                                  and optimal performance even in challenging
                                  environments{" "}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="row ml-0 mr-0 mt-10 resources-card"
                            style={{
                              height: 300,
                              width: "32%",
                              backgroundColor: "#E8D9E5",
                            }}
                            itemScope
                            itemType="https://schema.org/Product"
                          >
                            <div className="relative ">
                              {/* <img style={{ height: '30%', width: '30%', float: 'right', marginBottom: 10 }} src={Sealed} /> */}
                              <div className="p-10 pt-[100px]">
                                <div className="heading "  itemProp="name">
                                  Below Sealed Valves
                                </div>
                                <div className=" small-regular w-full font-14 pt-5" itemProp="description">
                                  Explore the engineering marvels behind Uni
                                  Klinger's Piston Valves, designed for precise
                                  fluid regulation in various industrial
                                  applications. Learn about the innovative
                                  features that ensure reliability, durability,
                                  and optimal performance even in challenging
                                  environments{" "}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="row ml-0 mr-0 mt-10 resources-card"
                            style={{
                              height: 300,
                              width: "32%",
                              backgroundColor: "#D5DDE4",
                            }}
                            itemScope
                            itemType="https://schema.org/Product"
                          >
                            <div className="relative ">
                              {/* <img style={{ height: '40%', width: '40%', float: 'right', marginBottom: 10 }} src={Steam} /> */}
                              <div className="p-10 pt-[100px]">
                                <div className="heading "  itemProp="name">Piston Valves</div>
                                <div className=" small-regular w-full font-14 pt-5" itemProp="description">
                                  Explore the engineering marvels behind Uni
                                  Klinger's Piston Valves, designed for precise
                                  fluid regulation in various industrial
                                  applications. Learn about the innovative
                                  features that ensure reliability, durability,
                                  and optimal performance even in challenging
                                  environments{" "}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* <img src={AboutImg3} alt="card-image" style={{ height: 600, width: 360 }} className="banner-image p-1 h-full" />
                  <div className="col-md-8 p-30 mt-10" style={{}}>
                    <div className="font-46" >
                      <span className="color-blue">Engineer. Innovate.</span><br />
                      <span className="color-red">Deliver. Secure. Repeat</span>
                    </div>
                    <div className="font-16-regular ">
                      Uniklinger embodies a philosophy of constant progress and innovation encapsulated in the phrase "Engineer. Innovate. Deliver. Secure." This commitment starts with their team of skilled engineers who design and develop top-notch fluid control and sealing solutions.
                      <br /><br />
                      Uniklinger doesn't just stop there, though. They prioritize continuous improvement and stay at the forefront of the industry by actively seeking new and advanced solutions. Delivering on their promises is equally important.
                    </div>

                  </div> */}
                      </div>
                      <div
                        role="tabpanel"
                        class="tab-pane"
                        id="settings"
                        style={
                          partnerTab === "rnd"
                            ? { display: "flex", flexDirection: "row" }
                            : { display: "none" }
                        }
                      >
                        {/* <img src={AboutImg3} alt="card-image" style={{ height: 600, width: 360 }} className="banner-image p-1 h-full" /> */}
                        <div className="col-md-8 p-30 mt-10" style={{}}>
                          <div className="font-46">
                            <span className="color-blue">
                              Engineer. Innovate.
                            </span>
                            <br />
                            <span className="color-red">
                              Deliver. Secure. Repeat
                            </span>
                          </div>
                          <div className="font-16-regular ">
                            Uniklinger embodies a philosophy of constant
                            progress and innovation encapsulated in the phrase
                            "Engineer. Innovate. Deliver. Secure." This
                            commitment starts with their team of skilled
                            engineers who design and develop top-notch fluid
                            control and sealing solutions.
                            <br />
                            <br />
                            Uniklinger doesn't just stop there, though. They
                            prioritize continuous improvement and stay at the
                            forefront of the industry by actively seeking new
                            and advanced solutions. Delivering on their promises
                            is equally important.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductsListing;
