import React,{useEffect,useState} from "react";

import Pharmaceuticals from "../assets/images/pharmaceuticals.jpg";
import "./IndustrySec.css";
import Tyre from "../assets/images/tyre.jpg";
import Paper from "../assets/images/paper.jpg";
import Textile from "../assets/images/textile.jpg";
import FoodBeverages from "../assets/images/food-beverages.jpg";
import ChemicalPetrochemical from "../assets/images/chemical-petrochemical.jpg";
import OilRefinery from "../assets/images/oil-refinery.jpg";
import ButtonArrow from "../assets/images/button-arrow.svg";
import { useNavigate } from "react-router-dom";


const IndustrySec = (props) => {
  console.log("props", props.industry);
  const navigate = useNavigate();
  const [zoomLevel, setZoomLevel] = useState(1);

  let data = {};
  if (props.industry === "food-beverages") {
    data.image = FoodBeverages;
    data.description =
      "Maintain hygiene and efficiency in food and beverage production with our reliable sealing and steam management solutions, ensuring safe and leak-free operations";
    data.title = "Food & Beverage";
  }
  if (props.industry === "paper") {
    data.image = Paper;
    data.description =
      "Improve paper production processes with our durable valves and steam traps, designed to handle high temperatures and pressures efficiently";
    data.title = "Paper";
  }
  if (props.industry === "textiles") {
    data.image = Textile;
    data.description =
      "Optimize textile manufacturing with our efficient steam and condensate management solutions, ensuring smooth and energy-efficient operations";
    data.title = "Textile";
  }
  if (props.industry === "tyre-manufacturing") {
    data.image = Tyre;
    data.description =
      "Enhance tyre production with our leak-proof valves and effective steam traps, designed to withstand the high temperatures and pressures of the manufacturing process";
    data.title = "Tyre Manufacturing";
  }
  if (props.industry === "oil-refinery") {
    data.image = OilRefinery;
    data.description =
      "Enhance efficiency and safety with our leak-proof valves, gaskets, and steam traps, designed to handle the demanding conditions of oil refining processes";
    data.title = "Oil Refineries";
    data.redirectUrl= 'https://uniklinger.com/main/solutions/edible-oil-industry';
  }
  if (props.industry === "chemical-petrochemical") {
    data.image = ChemicalPetrochemical;
    data.description =
      "Ensure zero leakage and optimal process control with our robust valves, gaskets, and condensate management solutions, tailored for chemical and petrochemical applications";
    data.title = "Chemical & Petrochemical Plants";
    data.redirectUrl='https://uniklinger.com/main/solutions/chemical-industry';

  }
  if (props.industry === "pharmaceutical") {
    data.image = Pharmaceuticals;
    data.description =
      "Meet stringent pharmaceutical standards with our high-performance valves, gaskets, and steam traps, ideal for applications involving pure steam and critical media";
    data.title = "Pharmaceuticals";
    data.redirect='https://uniklinger.com/main/solutions/pharma-industry';
  }
  useEffect(() => {
    // Detect screen width and zoom level
    const updateScreenInfo = () => {
  
      setZoomLevel(window.outerWidth / window.innerWidth);
    };

    // Initial detection
    updateScreenInfo();

    // Add resize listener
    window.addEventListener("resize", updateScreenInfo);

    return () => {
      window.removeEventListener("resize", updateScreenInfo);
    };
  }, []);
  return (
    <div
      className="flex w-[100%] mb-[8rem]"
      itemScope
      itemType="https://schema.org/CreativeWork"
    >
      <div
        className="w-[50%] flex flex-col justify-start"
        style={{ height: "600px", maxHeight: "600px" }}
      >
        <h1 className="flex justify-start text-6xl poppins">
          <span className="font-semibold poppins mr-[8px]" itemProp="headline">
            Solutions
          </span>{" "}
          <span itemProp="alternativeHeadline">Industry Wise</span>
        </h1>
        <div>
          <div>
            <div className="border relative border-[#CFCCC4] p-4">
              <img
                src={data.image}
                alt={data.title}
                itemProp="image"
                description={data.description}
              />
              <div className="absolute bottom-[30px] left-[40px]">
                <div
                  className="carousel-solution-title"
                  itemProp="name"
                >
                  {data.title}
                </div>
              </div>
            </div>
  
            <div>
              <div
                className="carousel-solution-description"
                itemProp="description"
              >
                {data.description}
              </div>
            </div>
            <div>
              <button
                onClick={() => {
                  navigate("/contact-us");
                }}
                className="card-button-class d-flex"
                style={{ marginTop: "20px" }}
                itemProp="potentialAction"
                itemScope
                itemType="https://schema.org/Action"
              >
                <span itemProp="name" className="pr-10">
                  Discover Now
                </span>
                <span>
                  <img
                    src={ButtonArrow}
                    alt="arrow"
                    description="arrow"
                    style={{ marginTop: "3px" }}
                  />
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="w-[50%] md:hidden">
        <div></div>
        <div></div>
      </div>
    </div>
  );
  
};

export default IndustrySec;
