import React, { useState, useEffect } from "react";
import { debounce } from 'lodash';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import product1 from "../assets/images/piston-valves.svg";
import product2 from "../assets/images/steam-traps.svg";
import product3 from "../assets/images/jointing.svg";
import product4 from "../assets/images/safety-control.svg";

import arrowImage from "../assets/images/product-arrow.svg";

import {  Box } from "@mui/material";
import "./customDots.css";
import Next from "../assets/images/nextArrow.svg";
import axios from 'axios';
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,

} from "@mui/material";
import "./ProductsOffer.css";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // Import the default styles

const NextArrow = React.memo((props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "white",
        zIndex: 9999,
        height: 64,
        width: 64,
        borderRadius: 100,
        right: 60,

        borderColor: " rgba(0, 0, 0, 0.1)",
        borderWidth: 1,
      }}
      onClick={onClick}
    >
      <img
        style={{
          position: "absolute",
          top: 21,
          left: 26,
        }}
        src={Next}
        alt="next"
        description="next-button"
      />
    </div>
  );
});

const PrevArrow = React.memo((props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "white",
        zIndex: 9999,
        height: 64,
        width: 64,
        borderRadius: 100,
        left: -15,
        top: 200,
        borderColor: "rgba(0, 0, 0, 0.1)",
        borderWidth: 1,
      }}
      onClick={onClick}
    >
      <img
        style={{
          position: "absolute",
          top: 21,
          left: 24,
        }}
        className="rotate-180 "
        src={Next}
        alt="next"
        description="next-button"
      />
    </div>
  );
});

export default function ProductsOffer() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const [cards, setCards] = useState([]);
  const [selectedProduct,setSelectedProduct] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [activeImage,setActiveImage]=useState()

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  var settings = {
    className: "center",
  
    dots: true,
    infinite: true,
    centerMode:true,
    centerPadding: "0px",
    slidesToShow: isMobile ? 1 : 4,
    speed: 500,
    nextArrow: isMobile ? null : <NextArrow />,
    prevArrow: isMobile ? null : <PrevArrow />,
   
    slidesToScroll: isMobile ? 1 : 4
,
    responsive: [
      {
        breakpoint: 768, // Mobile breakpoint
        settings: {
          slidesToShow: 1,
          centerPadding: "0px",
        },
      },
      {
        breakpoint: 1024, // Tablet breakpoint
        settings: {
          slidesToShow: 2,
          centerPadding: "0px",
        },
      },
      {
        breakpoint: 1200, // Larger tablet / small desktop
        settings: {
          slidesToShow: 2,
          centerPadding: "0px",
          nextArrow: <NextArrow />,
          prevArrow: <PrevArrow />,
        },
      }
    
     
    ],
  };
  
  useEffect(() => {
    getData();
  }, [selectedProduct]);
  const BaseUrl = "https://ukladmin.3mindsdigital.com";
  const getData = debounce(async () => {
    try {
      const response = await axios.get(
        `${BaseUrl}/api/product-details?filters[product_id][$eq]=${selectedProduct}&populate=*`
      );
      setCards(response.data.data);
      console.log('cards',cards);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  },300);

  const handleProductClick = (id) => {
    setSelectedProduct(id);
  };


  
  return (
    <div className="w-full mb-[4rem] mt-[60px]">
    <h1 className="text-[20px] md:text-4xl font-bold" itemProp="name">PRODUCTS WE OFFER</h1>

      <div className="w-full overflow-x-auto flex justify-center">
        <div className="flex pl-[400px] md:pl-0 md:w-[60%] justify-around">
        <div className="carousel-prod relative flex rounded-full flex-col items-center p-4 md:mx-2 w-[180px] h-[180px] hover:border-[#a6bcf9cc] cursor-pointer border-8" style={selectedProduct === 1?{border:'10px solid #a6bcf9cc'}:{}} onClick={()=>handleProductClick(1)}
           itemScope
           itemType="https://schema.org/Product">
                      <img
              src={product1}
              className="w-[11rem] h-[11rem] rounded-full mt-[-12px]"
              alt="Piston Valves & Bellow Seal Valves"
        description="Piston Valves & Bellow Seal Valves"
        itemProp="image"

            />
            <div className="text-white font-12 poppins-bold text-center mt-2 absolute bottom-[33px]" style={{width:'90%'}}
                        itemProp="name"
                        >
              Piston Valves &<div> Bellow Seal Valves</div>
            </div>
                     </div>
          <div className="carousel-prod-red relative rounded-full flex flex-col items-center p-4  md:mx-2 w-[180px] h-[180px] hover:border-[#fcb8ca] cursor-pointer border-8" style={selectedProduct === 3?{border:'10px solid #fcb8ca'}:{}} onClick={()=>handleProductClick(3)}
            itemScope
            itemType="https://schema.org/Product"
          >
                       <img
              src={product2}
              className="w-[11rem] h-[11rem] rounded-full mt-[-12px]"
              alt="Steam Trapping Condensate Recovery Solutions"
              description="Steam Trapping Condensate Recovery Solutions"
              itemProp="image"
            />
            <div className="text-white font-12 poppins-bold text-center mt-2 absolute bottom-[33px]" style={{width:'90%'}}  itemProp="name">
              Steam Trapping Condensate <div>Recovery Solutions</div>
            </div>
                      </div>
          <div className="carousel-prod relative rounded-full flex flex-col items-center p-4 md:mx-2 w-[180px] h-[180px] hover:border-[#a6bcf9cc] cursor-pointer border-8 z-index-9" style={selectedProduct === 4 ? {border:'10px solid #a6bcf9cc'} : {}} onClick={()=>handleProductClick(4)}
            itemScope
            itemType="https://schema.org/Product"
          >
                      <img
              src={product3}
              className="w-[11rem] h-[11rem] rounded-full mt-[-12px]"
              alt="Jointing Sheets & Gaskets"
              description="Jointing Sheets & Gaskets"
              itemProp="image"
            />
            <div className="text-white font-12 poppins-bold text-center mt-2 absolute bottom-[33px]" style={{width:'90%'}}       itemProp="name">
              Jointing Sheets & <div>Gaskets</div>
            </div>
                      </div>
          <div className="carousel-prod-red relative rounded-full flex flex-col items-center p-4 md:mx-2 w-[180px] h-[180px] hover:border-[#fcb8ca] cursor-pointer border-8 z-index-9" style={selectedProduct === 2?{border:'10px solid #fcb8ca'}:{}} onClick={()=>handleProductClick(2)}
          itemScope
          itemType="https://schema.org/Product">
                      <img
              src={product4}
              className="w-[11rem] h-[11rem] rounded-full mt-[-12px]"
              alt="Safety & Control Valves"
              description="Safety & Control Valves"
              itemProp="image"
            />
            <div className="text-white font-12 poppins-bold text-center mt-2 absolute bottom-[33px]" style={{width:'90%'}}          itemProp="name">
              Safety & Control <div>Valves</div>
            </div>
                      </div>
       
          
        
        </div>
      </div>
      <div className="products-slider md:block mobileHide">
        <Slider
          {...settings}
          className="mt-[50px] h-[45rem] pl-12"
          style={{ width: "105%" }}
        >
          {cards.map((card,index)=>{
            console.log(card?.attributes?.product?.data?.attributes?.redirect_url,"card")
            return(
          <Card onClick={()=>{window.location.href=card?.attributes?.product?.data?.attributes?.redirect_url}}
          
         
          itemScope
          itemType="https://schema.org/Product"
          key={index} sx={{ width: 398, height: 380,
            transition: 'transform 0.2s ease-in-out', // Smooth scaling effect
            }}>
            <CardActionArea sx={{ height: "100%" }}>
              <CardMedia
                component="img"
                sx={{ objectFit: "cover", height: 240 }}
                image={BaseUrl.concat(card.attributes.Image.data.attributes.url)}
                alt="green iguana"
                itemProp="image"
              />
              <CardContent
                sx={{
                  height: "calc(100% - 240px)",
                  background: "#FFFFFF",
                  border: "1px solid rgba(0, 0, 0, 0.1) ",
                }}
              >
                {" "}
                {/* Adjusted the height of the content */}
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  sx={{
                    color: "#2A2A2A",
                    fontWeight: 700,
                    fontSize: "20px",
                  }}
                  fontWeight="bold"
                  itemProp="name"
                >
                  {card.attributes.Title}
                </Typography>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      fontSize: "14px",
                      color: "#2A2A2A",
                      fontWeight: 400,
                    }}
                    itemProp="description"
                  >
                    {card.attributes.Description.slice(0,80)}
                    <span
                      style={{
                        color: "#222222",
                        fontSize: 14,
                        fontWeight: 600,
                        paddingLeft:'5px'
                      }}
                    >
                      Read More
                    </span>
                  </Typography>
                  <img
                    src={arrowImage}
                    alt="arrow"
                    style={{
                      width: "48px",
                      height: "48px",
                      marginLeft: "8px",
                    }}
                    description="arrow"
                    
                  
                  />
                </Box>
              </CardContent>
            </CardActionArea>
          </Card>
          )
          })}
        </Slider>
      </div>
      <div className="products-slider md:hidden">
        <Slider {...settings} className="mt-[50px]  md:h-[500px] ">
        {cards.map((card,index)=>(
            <Card  key={index}  itemScope
            itemType="https://schema.org/Product"  sx={{ width: isMobile ? 315 : 375 }}>
            <CardActionArea>
              <CardMedia
                component="img"
                sx={{ objectFit: "cover", height: isMobile ? "85px" : "247px" }}
                image={BaseUrl.concat(card.attributes.Image.data.attributes.url)}
                alt="green iguana"
                itemProp="image"
              />
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  sx={{ color: "#2A2A2A", fontWeight: 700, fontSize: "20px" }}
                  itemProp="name"
                >
                  {card.attributes.Title}
                </Typography>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ fontSize: "14px", color: "#2A2A2A", fontWeight: 400 }}
                    itemProp="description"
                  >
                    {card.attributes.Description.slice(0,80)}
                    <span
                      style={{
                        color: "#222222",
                        fontSize: 14,
                        fontWeight: 600,
                        paddingLeft:'5px'
                      }}
                    >
                      Read More
                    </span>
                  </Typography>
                  <img
                    src={arrowImage}
                    alt="arrow"
                    style={{ width: "48px", height: "48px", marginLeft: "8px" }}
                    description="arrow"
                  />
                </Box>
              </CardContent>
            </CardActionArea>
          </Card>
          ))}
        </Slider>
      </div>

     


      
    </div>
  );
}
