import React, { useState, useRef,useEffect } from "react";
import SmallMap from "../assets/images/small-map.png";
import WhitePhone from "../assets/images/white-phone.svg";
import WhiteMail from "../assets/images/white-mail.svg";
import TextField from "@mui/material/TextField";
import ButtonArrow from "../assets/images/button-arrow.svg";
import WhiteLocation from "../assets/images/white-location.svg";
import * as yup from 'yup';

const Form = () => {
  const [screenWidth, setScreenWidth] = useState(0);
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    resume: null,
    message: "",
  });
  const [errors, setErrors] = useState({});
  const uploadInputRef = useRef(null);

  const validationSchema = yup.object().shape({
    fullName: yup.string().required('Full Name is required'),
    email: yup.string().email('Invalid email format').required('Email ID is required'),
    phoneNumber: yup.string().matches(/^[0-9]+$/, 'Phone Number must be numeric').required('Phone Number is required'),
    resume: yup.mixed().required('Resume is required'),
    message: yup.string().required('Message is required'),
  });

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    const fieldValue = files ? files[0] : value;

    setFormData({
      ...formData,
      [name]: fieldValue,
    });

    validationSchema.validateAt(name, { [name]: fieldValue })
      .then(() => {
        setErrors(prevErrors => ({ ...prevErrors, [name]: "" }));
      })
      .catch((err) => {
        setErrors(prevErrors => ({ ...prevErrors, [name]: err.message }));
      });
  };


 
  const handleSubmit = (e) => {
    e.preventDefault();
    validationSchema.validate(formData, { abortEarly: false })
      .then(() => {
        console.log(formData);
      })
      .catch((err) => {
        const newErrors = err.inner.reduce((acc, curr) => {
          acc[curr.path] = curr.message;
          return acc;
        }, {});
        setErrors(newErrors);
      });
  };

  useEffect(() => {
    // Create a new ResizeObserver instance
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        setScreenWidth(entry.contentRect.width);
      }
    });

    // Start observing the selected element
    resizeObserver.observe(document.body);
  }, [screenWidth]);


  return (
    <div
      className="form-container flex-align-center"
      id="contact-us-form"
      style={{ paddingTop: "100px" }}
      itemScope
      itemType="http://schema.org/ContactPage"
    >
      <div class="w-90 flex-align-center flex form-wrapper">
      <div class="content-section" itemScope itemType="http://schema.org/Organization">
          <div className="form-content">
            <div className="content-title">Visit Us here</div>
            <div className="content-headoffice" itemProp="location" itemScope itemType="http://schema.org/Place">
            <span itemProp="name">UniKlinger Head Office</span>
          </div>
          </div>
          <div class="pt-10 pb-10 d-flex">
            <div className="pr-10">
              <img
                src={WhiteLocation}
                alt="locate-us"
                style={screenWidth > 820 ?{ width: "25px" } : { width: '40px' }}
                className="mt-5"
                description="locate-us"
                itemProp="image"

              />
            </div>
            <div className="poppins-regular font-16 color-white" itemProp="address" itemScope itemType="http://schema.org/PostalAddress">
            <span itemProp="streetAddress">Kohinoor Estate, SC 1, 5th Floor Opp. Bajaj Garden,</span><br />
            <span itemProp="addressLocality">Pune - Mumbai Road Khadki, Pune</span> - <span itemProp="postalCode">411003</span>
          </div>
          </div>
          <div>
            <img
              src={SmallMap}
              alt="small-map"
              className="banner-image pt-10 pb-10"
              description="map"
              itemProp="image"

            />
          </div>
          <div class="pt-10 pb-10 d-flex">
            <span className="pr-10">
            <img src={WhitePhone} alt="call-us" description="call-us" itemProp="telephone" />
            </span>
            <span className="poppins-regular font-16">
            <a href="tel:+912041023000" style={{ color: "white" }} itemProp="telephone" title="phone">
                +912041023000
              </a>
            </span>
          </div>
          <div class="pt-10 pb-10 d-flex">
            <span className="pr-10">
              <img src={WhiteMail} alt="mail-to" description="mail-to" />
            </span>
            <span className="poppins-regular font-16">
            <a href="mailto:salescso@uniklinger.com" style={{ color: "white" }} itemProp="email" title="email">

                salescso@uniklinger.com
              </a>
            </span>
          </div>
        </div>
        <form
          class="fields-section bg-white p-40"
          style={{
            height: "570px",
            width: "570px",
            background: "#f9fbfc",
            border: "1px solid #e6e6e6",
          }}
          itemScope
        itemType="http://schema.org/ContactForm"
        >
          <div className="pb-30">
            <TextField
              id="standard-size-normal"
              label="Full Name"
              variant="standard"
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
              error={!!errors.fullName}
              helperText={errors.fullName}
              size="normal"
              className="w-100"
              InputProps={{
                style: { fontSize: "16px" },
              }}
              InputLabelProps={{
                style: {
                  fontSize: "16px",
                  position: "absolute",
                  top: "-10px",
                },
              }}
              itemProp="name"
            />
          </div>
          <div className="pb-30">
            <TextField
              id="standard-size-normal"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              error={!!errors.email}
              helperText={errors.email}
              label="Email ID"
              variant="standard"
              className="w-100"
              InputProps={{
                style: { fontSize: "16px" },
              }}
              InputLabelProps={{
                style: {
                  fontSize: "16px",
                  position: "absolute",
                  top: "-10px",
                },
              }}
              itemProp="email"

            />
          </div>
          <div className="pb-30">
            <TextField
              id="standard-size-normal"
              label="Phone Number"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              error={!!errors.phoneNumber}
              helperText={errors.phoneNumber}
              variant="standard"
              className="w-100"
              InputProps={{
                style: { fontSize: "16px" },
              }}
              InputLabelProps={{
                style: {
                  fontSize: "16px",
                  position: "absolute",
                  top: "-10px",
                },
              }}
              itemProp="telephone"

            />
          </div>
          <div className="pb-30">
          <TextField
            id="standard-size-normal"
            label="Your Location (optional)"
            variant="standard"
            className="w-100"
            InputProps={{
              style: { fontSize: "16px" },
            }}
            InputLabelProps={{
              style: {
                fontSize: "16px",
                position: "absolute",
                top: "-10px",
              },
            }}
            itemProp="address"

          />
        </div>
          <div className="pb-30">
            <TextField
              id="standard-size-normal"
              name="message"
              value={formData.message}
              onChange={handleChange}
              error={!!errors.message}
              helperText={errors.message}
              label="Your Message"
              variant="standard"
              className="w-100"
              InputProps={{
                style: { fontSize: "16px" },
              }}
              InputLabelProps={{
                style: {
                  fontSize: "16px",
                  position: "absolute",
                  top: "-10px",
                },
              }}
              itemProp="message"

            />
          </div>
          <div>
            <button
              onClick={(e) => {
                handleSubmit(e);
              }}
              className="button-class d-flex"
              itemProp="submit"

            >
              <span className="pr-10">Submit</span>
              <span>
                <img src={ButtonArrow} alt="arrow" className="mt-8"  description="arrow"/>
              </span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Form;
