import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import banner2 from "../assets/images/mainbanner.png";
import mobilebanner from "../assets/images/topBanner.png";

import {
  CardActionArea,

  CardMedia,
  Card,
} from "@mui/material";

import Next from "../assets/images/nextArrow.svg";
import { Link, useNavigate } from "react-router-dom";

const Arrow = React.memo(({ className, style, onClick, isNext }) => (
  <div
    className={className}
    style={{
      ...style,
      display: "block",
      background: "white",
      zIndex: 9999,
      height: 64,
      width: 64,
      borderRadius: 100,
      borderColor: "rgba(0, 0, 0, 0.1)",
      borderWidth: 1,
      right: isNext ? 61 : undefined,
      left: isNext ? undefined : 24,
    }}
    onClick={onClick}
  >
    <img
      style={{
        position: "absolute",
        top: 21,
        left: isNext ? 26 : 24,
        transform: isNext ? undefined : "rotate(180deg)",
      }}
      alt={isNext ? "Next" : "Previous"}
      src={Next}
    />
  </div>
));

export default function SimpleSlider() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 1200);

  useEffect(() => {
    const handleResize = () => {setIsMobile(window.innerWidth < 768);      setIsLargeScreen(window.innerWidth >= 1200);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  var settings = {
    className: "center",
    centerMode: true,
    infinite: false,
    centerPadding: "0px",
    slidesToShow: 1,
    //nextArrow: <Arrow isNext={true} />,
   // prevArrow: <Arrow isNext={false} />,
    slidesToScroll: 1,
    speed: 500,
    // dots: isMobile,

    responsive: [
      {
        breakpoint: 768, // Mobile breakpoint
        settings: {
          slidesToShow: 1,
          centerPadding: "0px",
        },
      },
      {
        breakpoint: 1024, // Tablet breakpoint
        settings: {
          slidesToShow: 1,
          centerPadding: "0px",
        },
      },
      {
        breakpoint: 1200, // Tablet breakpoint
        settings: {
          slidesToShow: 1,
          centerPadding: "0px",
        },
      },
      {
        breakpoint: 1400, // Tablet breakpoint
        settings: {
          slidesToShow: 1,
          centerPadding: "0px",
        },
      },
      {
        breakpoint: 1800, // Tablet breakpoint
        settings: {
          slidesToShow: 1,
          centerPadding: "0px",
        },
      },
    ],
  };

  return (
    <div
      className="top-slider"
      style={{
        width: "100%",
        position: "relative",
        paddingTop:'100px',margin:'auto'
      }}
    >
      <Slider sx={{ padding: isMobile ?0 : 0 }} {...settings}>
        <Card
          sx={{
            maxWidth: isLargeScreen ? 1500 : 1300, // Adjust maxWidth for large screens
            maxHeight: isLargeScreen ? 600 : 507, // Adjust maxHeight for large screens
            height: { xs: 512, md: "auto" }, // Maintain height flexibility
            margin: isLargeScreen ? '0 auto' : '10px', // Center the card on large screens
          }}
        >
          <CardActionArea>
            <Link to={"https://uniklinger.com/Achema/#partnerWithUs"}>
              <CardMedia
                component="img"
                sx={{ height: "100%", width: "100%" }}
                image={isMobile ? mobilebanner : banner2}
                alt="partner-with-us"
               

              />
            </Link>
          </CardActionArea>
        </Card>
      </Slider>
    </div>
  );
}
